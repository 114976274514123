const actions = {

  SHIFTS_STATUS_READ_BEGIN: 'SHIFTS_STATUS_READ_BEGIN',
  SHIFTS_STATUS_READ_SUCCESS: 'SHIFTS_STATUS_READ_SUCCESS',
  SHIFTS_STATUS_READ_ERR: 'SHIFTS_STATUS_READ_ERR',

  SHIFTS_FILTER_READ_BEGIN: 'SHIFTS_FILTER_READ_BEGIN',
  SHIFTS_FILTER_READ_SUCCESS: 'SHIFTS_FILTER_READ_SUCCESS',
  SHIFTS_FILTER_READ_ERR: 'SHIFTS_FILTER_READ_ERR',

  SHIFTS_READ_BEGIN: 'SHIFTS_READ_BEGIN',
  SHIFTS_READ_SUCCESS: 'SHIFTS_READ_SUCCESS',
  SHIFTS_READ_ERR: 'SHIFTS_READ_ERR',

  ONCALL_SHIFTS_READ_BEGIN: 'ONCALL_SHIFTS_READ_BEGIN',
  ONCALL_SHIFTS_READ_SUCCESS: 'ONCALL_SHIFTS_READ_SUCCESS',
  ONCALL_SHIFTS_READ_ERR: 'ONCALL_SHIFTS_READ_ERR',

  SHIFTS_SINGLE_PAGE_READ_BEGIN: 'SHIFTS_SINGLE_PAGE_READ_BEGIN',
  SHIFTS_SINGLE_PAGE_READ_SUCCESS: 'SHIFTS_SINGLE_PAGE_READ_SUCCESS',
  SHIFTS_SINGLE_PAGE_READ_ERR: 'SHIFTS_SINGLE_PAGE_READ_ERR',


  shiftsFilterReadErr: (err) => {
    return {
      type: actions.SHIFTS_FILTER_READ_ERR,
      err,
    };
  },

  shiftsFilterReadBegin: () => {
    return {
      type: actions.SHIFTS_FILTER_READ_BEGIN,
    };
  },

  shiftsFilterReadSuccess: (data) => {
    return {
      type: actions.SHIFTS_FILTER_READ_SUCCESS,
      data,
    };
  },

  shiftsStatusReadBegin: () => {
    return {
      type: actions.SHIFTS_STATUS_READ_BEGIN,
    };
  },

  shiftsStatusReadSuccess: (data) => {
    return {
      type: actions.SHIFTS_STATUS_READ_SUCCESS,
      data,
    };
  },


  shiftsStatusReadErr: (err) => {
    return {
      type: actions.SHIFTS_STATUS_READ_ERR,
      err,
    };
  },

  shiftsReadBegin: () => {
    return {
      type: actions.SHIFTS_READ_BEGIN,
    };
  },

  shiftsReadSuccess: (data) => {
    return {
      type: actions.SHIFTS_READ_SUCCESS,
      data,
    };
  },

  shiftsReadErr: (err) => {
    return {
      type: actions.SHIFTS_READ_ERR,
      err,
    };
  },



  oncallShiftsReadBegin: () => {
    return {
      type: actions.ONCALL_SHIFTS_READ_BEGIN,
    };
  },

  oncallShiftsReadSuccess: (data) => {
    return {
      type: actions.ONCALL_SHIFTS_READ_SUCCESS,
      data,
    };
  },

  oncallShiftsReadErr: (err) => {
    return {
      type: actions.ONCALL_SHIFTS_READ_ERR,
      err,
    };
  },

  filterSinglePageReadBegin: () => {
    return {
      type: actions.SHIFTS_SINGLE_PAGE_READ_BEGIN,
    };
  },

  filterSinglePageReadSuccess: (data) => {
    return {
      type: actions.SHIFTS_SINGLE_PAGE_READ_SUCCESS,
      data,
    };
  },

  filterSinglePageReadErr: (err) => {
    return {
      type: actions.SHIFTS_SINGLE_PAGE_READ_ERR,
      err,
    };
  },
};

export default actions;
