import actions from './actions';

const {
  SHIFTS_STATUS_READ_BEGIN,
  SHIFTS_STATUS_READ_SUCCESS,
  SHIFTS_STATUS_READ_ERR,

  SHIFTS_FILTER_READ_BEGIN,
  SHIFTS_FILTER_READ_SUCCESS,
  SHIFTS_FILTER_READ_ERR,
  
  SHIFTS_READ_BEGIN,
  SHIFTS_READ_SUCCESS,
  SHIFTS_READ_ERR,

  ONCALL_SHIFTS_READ_BEGIN,
  ONCALL_SHIFTS_READ_SUCCESS,
  ONCALL_SHIFTS_READ_ERR,

  SHIFTS_SINGLE_PAGE_READ_BEGIN,
  SHIFTS_SINGLE_PAGE_READ_SUCCESS,
  SHIFTS_SINGLE_PAGE_READ_ERR,
} = actions;

const initialState = {
  shifts: [],
  shift: [],
  shiftsFilterData: [],
  loading: false,
  error: null,
};

const shiftReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case SHIFTS_STATUS_READ_BEGIN:
      return {
        ...initialState,
        loading: true,
      };
    case SHIFTS_STATUS_READ_SUCCESS:
      return {
        ...initialState,
        shifts: data,
        loading: false,
      };
    case SHIFTS_STATUS_READ_ERR:
      return {
        ...initialState,
        error: err,
        loading: false,
      };
    case ONCALL_SHIFTS_READ_BEGIN:
      return {
        ...initialState,
        loading: true,
      };
    case ONCALL_SHIFTS_READ_SUCCESS:
      return {
        ...initialState,
        oncall_shifts: data,
        loading: false,
      };
    case ONCALL_SHIFTS_READ_ERR:
      return {
        ...initialState,
        error: err,
        loading: false,
      };
    case SHIFTS_READ_BEGIN:
      return {
        ...initialState,
        loading: true,
      };
    case SHIFTS_READ_SUCCESS:
      return {
        ...initialState,
        shifts: data,
        loading: false,
      };
    case SHIFTS_READ_ERR:
      return {
        ...initialState,
        error: err,
        loading: false,
      };
    case SHIFTS_FILTER_READ_BEGIN:
      return {
        ...initialState,
        loading: true,
      };
    case SHIFTS_FILTER_READ_SUCCESS:
      return {
        ...initialState,
        shiftsFilterData: data,
        loading: false,
      };
    case SHIFTS_FILTER_READ_ERR:
      return {
        ...initialState,
        error: err,
        loading: false,
      };
    case SHIFTS_SINGLE_PAGE_READ_BEGIN:
      return {
        ...initialState,
        loading: true,
      };
    case SHIFTS_SINGLE_PAGE_READ_SUCCESS:
      return {
        ...initialState,
        job: data,
        loading: false,
      };
    case SHIFTS_SINGLE_PAGE_READ_ERR:
      return {
        ...initialState,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};

export default shiftReducer;
