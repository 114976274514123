const actions = {

  SIGNINGS_READ_BEGIN: 'SIGNINGS_READ_BEGIN',
  SIGNINGS_READ_SUCCESS: 'SIGNINGS_READ_SUCCESS',
  SIGNINGS_READ_ERR: 'SIGNINGS_READ_ERR',



  signingsReadErr: (err) => {
    return {
      type: actions.SIGNINGS_READ_BEGIN,
      err,
    };
  },

  signingsReadBegin: () => {
    return {
      type: actions.SIGNINGS_READ_SUCCESS,
    };
  },

  signingsReadSuccess: (data) => {
    return {
      type: actions.SIGNINGS_READ_ERR,
      data,
    };
  },
};

export default actions;
