import { Spin } from 'antd';
import React, { lazy, Suspense, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Axios from './axios';
import { useTranslation } from 'react-i18next';
import Ecommerce from './ecommerce';
import Features from './features';
import Gallery from './gallery';
import Pages from './pages';
import Users from './users';
import Widgets from './widgets';
import withAdminLayout from '../../layout/withAdminLayout';
import DataTables from '../../container/table/DataTable';

const Charts = lazy(() => import('./charts'));
const KnowledgeBase = lazy(() => import('../../container/pages/knowledgeBase/Index'));
const AllArticle = lazy(() => import('../../container/pages/knowledgeBase/AllArticle'));
const KnowledgeSingle = lazy(() => import('../../container/pages/knowledgeBase/SingleKnowledge'));
const Components = lazy(() => import('./components'));
const Task = lazy(() => import('../../container/task/Index'));
const Tickets = lazy(() => import('../../container/supportTicket/Index'));
const AddTicket = lazy(() => import('../../container/supportTicket/AddSupport'));
const TicketDetails = lazy(() => import('../../container/supportTicket/SupportTicketDetails'));
const Courses = lazy(() => import('../../container/course/Index'));
const CourseDetails = lazy(() => import('../../container/course/CourseDetails'));
const Contact = lazy(() => import('../../container/contact/Contact'));
const ContactGrid = lazy(() => import('../../container/contact/ContactGrid'));
const ContactAddNew = lazy(() => import('../../container/contact/AddNew'));
const Calendars = lazy(() => import('../../container/calendar/Calendar'));
const Import = lazy(() => import('../../container/importExport/Import'));
const Export = lazy(() => import('../../container/importExport/Export'));
const ToDo = lazy(() => import('../../container/toDo/ToDo'));
const Note = lazy(() => import('../../container/note/Note'));
const Projects = lazy(() => import('./projects'));
const Myprofile = lazy(() => import('../../container/profile/myProfile/Index'));
const Chat = lazy(() => import('../../container/chat/ChatApp'));
const Inbox = lazy(() => import('../../container/email/Email'));
const Maps = lazy(() => import('./maps'));
const Editors = lazy(() => import('../../container/pages/Editor'));
const Icons = lazy(() => import('./icons'));
const Tables = lazy(() => import('./table'));
const Available = lazy(() => import('../../container/shifts/Available'));
const ShiftList = lazy(() => import('../../container/shifts/admin/AdminShiftList'));
const Shifts = lazy(() => import('../../container/shifts/Shifts'));
const ShiftsDetails = lazy(() => import('../../container/shifts/ShiftsDetails'));
const ShiftApply = lazy(() => import('../../container/shifts/shiftsApplication'));
const Firebase = lazy(() => import('./firestore'));
const NotFound = lazy(() => import('../../container/pages/404'));
const SalaryInfo = lazy(() => import('../../container/salary/SalaryInfo'));
const Paychecks = lazy(() => import('../../container/salary/paycheck/Paychecks'));
const Signings = lazy(() => import('../../container/signings/Signings'));
const Timeline = lazy(() => import('../../container/profile/myProfile/overview/Timeline'));
const UserListTable = lazy(() => import('../../container/pages/overview/UserTable'));

const Admin = React.memo(() => {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <Suspense
      fallback={
        <div className="spin flex items-center justify-center bg-white dark:bg-dark h-screen w-full fixed z-[999] ltr:left-0 rtl:right-0 top-0">
          <Spin />
        </div>
      }
    >
      <Routes>
        <Route index path="/dashboard/*" element={<Timeline />} />
        <Route path="salaryinfo" element={<SalaryInfo />} />
        <Route path="paychecks" element={<Paychecks />} />
        <Route path="signings" element={<Signings />} />
        <Route path="shifts/admin/list" element={<DataTables renderContent={() => <ShiftList />} breadcrumbName={t('Available shifts')} />} />
        <Route path="shifts/available" element={<Available />} />
        <Route path="shifts/not-hold" element={<Shifts pageShow={'not-hold'} />} />
        <Route path="shifts/ongoing" element={<Shifts pageShow={'ongoing'} />} />
        <Route path="shifts/held" element={<Shifts pageShow={'held'} />} />
        <Route path="shifts/approved" element={<Shifts pageShow={'approved'} />} />
        <Route path="shifts/paid" element={<Shifts pageShow={'paid'} />} />
        <Route path="shifts/apply" element={<ShiftApply pageShow={'apply'} />} />
        <Route path="shiftsDetails/:id" element={<ShiftsDetails />} />
        <Route path="calendar/*" element={<Calendars />} />
        <Route path="main/chat/*" element={<Chat />} />
        <Route path="users/list" element={<DataTables renderContent={() => <UserListTable />} breadcrumbName={t('User List')}/>} />

        <Route path="pages/*" element={<Pages />} />
        <Route path="gallery/*" element={<Gallery />} />
        <Route path="all-articles" element={<AllArticle />} />
        <Route path="knowledgeBase/*" element={<KnowledgeBase />} />
        <Route path="knowledgebaseSingle/:id" element={<KnowledgeSingle />} />
        <Route path="components/*" element={<Components />} />
        <Route path="charts/*" element={<Charts />} />
        <Route path="task/*" element={<Task />} />
        <Route path="users/*" element={<Users />} />
        <Route path="support/tickets/*" element={<Tickets />} />
        <Route path="support/tickets/add" element={<AddTicket />} />
        <Route path="support/ticketDetails/:id" element={<TicketDetails />} />
        <Route path="course/courseDetails/:id" element={<CourseDetails />} />
        <Route path="course/*" element={<Courses />} />
        <Route path="contact/list" element={<Contact />} />
        <Route path="contact/grid" element={<ContactGrid />} />
        <Route path="contact/addNew" element={<ContactAddNew />} />
        <Route path="importExport/import" element={<Import />} />
        <Route path="importExport/export" element={<Export />} />
        <Route path="to-do" element={<ToDo />} />
        <Route path="note/*" element={<Note />} />
        <Route path="features/*" element={<Features />} />
        <Route path="axios/*" element={<Axios />} />
        <Route path="firestore/*" element={<Firebase />} />
        <Route path="project/*" element={<Projects />} />
        <Route path="profile/myProfile/*" element={<Myprofile />} />
        <Route path="ecommerce/*" element={<Ecommerce />} />
        <Route path="email/*" element={<Inbox />} />
        <Route path="maps/*" element={<Maps />} />
        <Route path="editor" element={<Editors />} />
        <Route path="icons/*" element={<Icons />} />
        <Route path="tables/*" element={<Tables />} />
        <Route path="widgets/*" element={<Widgets />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
});

export default withAdminLayout(Admin);
