import actions from './actions';

const initialState = {
  userInfo: null,
  allUsers: null,
  loading: false,
  error: null
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_USER_INFO_REQUEST:
      return {
        ...state,
        loading: true
      };
    case actions.GET_USER_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        userInfo: action.payload
      };
    case actions.GET_USER_INFO_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case actions.GET_USER_ALL_DATA_REQUEST:
      return {
        ...state,
        loading: true
      };
    case actions.GET_USER_ALL_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        allUsers: action.payload
      };
    case actions.GET_USER_ALL_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

export default userReducer;
