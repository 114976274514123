/* eslint-disable react/jsx-no-bind */
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'antd';
import { PageHeader } from '../../components/page-headers/page-headers';
import { GlobalUtilityStyle } from '../styled';
import { getAllUsers } from '../../redux/users/actionCreator';

function DataTables({ renderContent, breadcrumbName }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getAllUsers());
  }, []);

  const PageRoutes = [
    {
      path: 'index',
      breadcrumbName: t('Dashboard')
    },
    {
      path: 'first',
      breadcrumbName: breadcrumbName,
    },
  ];

  return (
    <>
      <PageHeader
        routes={PageRoutes}
        title={breadcrumbName}
        className="flex items-center justify-between px-8 xl:px-[15px] pt-2 pb-6 sm:pb-[30px] bg-transparent sm:flex-col"
      />
      <div className="min-h-[715px] lg:min-h-[580px] flex-1 h-auto px-8 xl:px-[15px] pb-[30px] bg-transparent">
        <GlobalUtilityStyle>
          <Row gutter={15}>
            <Col xs={24} className="mb-[25px]">
              <div className="bg-white dark:bg-white10 m-0 p-0 mb-[25px] rounded-10 relative">
                <div className="p-[25px]">
                  {renderContent && renderContent()}
                </div>
              </div>
            </Col>
          </Row>
        </GlobalUtilityStyle>
      </div>
    </>
  );
}

export default DataTables;
