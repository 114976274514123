const actions = {

    // Action types
    GET_USER_INFO_REQUEST: 'GET_USER_INFO_REQUEST',
    GET_USER_INFO_SUCCESS: 'GET_USER_INFO_SUCCESS',
    GET_USER_INFO_FAILURE: 'GET_USER_INFO_FAILURE',

    GET_USER_ALL_DATA_REQUEST: 'GET_USER_ALL_DATA_REQUEST',
    GET_USER_ALL_DATA_SUCCESS: 'GET_USER_ALL_DATA_SUCCESS',
    GET_USER_ALL_DATA_FAILURE: 'GET_USER_ALL_DATA_FAILURE',
    // Action creators
    getUserInfoRequest: () => {
        return {
            type: actions.GET_USER_INFO_REQUEST
        }
    },

    getUserInfoSuccess: (userInfo) => {
        return {
            type: actions.GET_USER_INFO_SUCCESS,
            payload: userInfo
        }
    },

    getUserInfoFailure: (error) => {
        return {
            type: actions.GET_USER_INFO_FAILURE,
            payload: error
        }
    },

    getAllUserDataRequest: () => {
        return {
            type: actions.GET_USER_ALL_DATA_REQUEST
        }
    },

    getAllUserDataSuccess: (userData) => {
        return {
            type: actions.GET_USER_ALL_DATA_SUCCESS,
            payload: userData
        }
    },

    getAllUserDataFailure: (error) => {
        return {
            type: actions.GET_USER_ALL_DATA_FAILURE,
            payload: error
        }
    },

}


export default actions;
