const actions = {
  READ_NURSING_BEGIN: 'READ_NURSING_BEGIN',
  READ_NURSING_SUCCESS: 'READ_NURSING_SUCCESS',
  READ_NURSING_ERR: 'READ_NURSING_ERR',
  
  readNursingBegin: () => {
    return {
      type: actions.READ_NURSING_BEGIN,
    };
  },

  readNursingSuccess: (data) => {
    return {
      type: actions.READ_NURSING_SUCCESS,
      data,
    };
  },

  readNursingErr: (err) => {
    return {
      type: actions.READ_NURSING_ERR,
      err,
    };
  },
};

export default actions;
