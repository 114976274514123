import actions from './actions';

const initialState = {
  loading: false,
  error: null,
  posts: [],
  postLoading: false,
};

const {
  POST_DATA_BEGIN,
  POST_DATA_SUCCESS,
  POST_DATA_ERR,
} = actions;

const PostReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case POST_DATA_BEGIN:
      return {
        ...state,
        postLoading: true,
      };
    case POST_DATA_SUCCESS:
      return {
        ...state,
        posts: data,
        postLoading: false,
      };
    case POST_DATA_ERR:
      return {
        ...state,
        error: err,
        postLoading: false,
      };
    default:
      return state;
  }
};

export default PostReducer;
