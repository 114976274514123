import actions from './actions';

const { READ_NURSING_BEGIN, READ_NURSING_SUCCESS, READ_NURSING_ERR } = actions;

const initialState = {
  nursing: [],
  loading: false,
  error: null,
};

const nursings = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case READ_NURSING_BEGIN:
      return {
        ...initialState,
        loading: true,
      };
    case READ_NURSING_SUCCESS:
      return {
        ...initialState,
        nursing: data,
        loading: false,
      };
    case READ_NURSING_ERR:
      return {
        ...initialState,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};

export default nursings;
