import actions from './actions';
import staticData from '../../demoData/projectData.json';

const {
    SALARY_READ_BEGIN,
    SALARY_READ_SUCCESS,
    SALARY_READ_ERR,
} = actions;


const initialState = {
    data: [],
    loading: false,
    error: null,
};

const salaryReadReducer = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case SALARY_READ_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case SALARY_READ_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case SALARY_READ_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };
        default:
            return state;
    }
};

export default salaryReadReducer;
