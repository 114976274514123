import {
  UilCalendarAlt,
  UilChat,
  UilCreateDashboard,
  UilEnvelope,
  UilMoneyWithdrawal,
  UilEditAlt,
  UilMoneyWithdraw,
  UilChatBubbleUser
} from '@iconscout/react-unicons';
import { Menu } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import UilEllipsisV from '@iconscout/react-unicons/icons/uil-ellipsis-v';
import propTypes from 'prop-types';
import { changeDirectionMode, changeLayoutMode, changeMenuMode } from '../redux/themeLayout/actionCreator';

function MenuItems({ toggleCollapsed }) {
  const { t } = useTranslation();

  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  const { topMenu, userInfo } = useSelector((state) => {
    return {
      topMenu: state.ChangeLayoutMode.topMenu,
      userInfo: state.user.userInfo
    };
  });

  const dispatch = useDispatch();

  const path = '/app';

  const pathName = window.location.pathname;
  const pathArray = pathName.split(path);
  const mainPath = pathArray[1];
  const mainPathSplit = mainPath.split('/');

  const [openKeys, setOpenKeys] = React.useState(
    !topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : [],
  );

  const onOpenChange = (keys) => {
    setOpenKeys(keys[keys.length - 1] !== 'recharts' ? [keys.length && keys[keys.length - 1]] : keys);
  };

  const onClick = (item) => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };


  const changeNavbar = (topMode) => {
    const html = document.querySelector('html');
    if (topMode) {
      html.classList.add('hexadash-topmenu');
    } else {
      html.classList.remove('hexadash-topmenu');
    }
    dispatch(changeMenuMode(topMode));
  };

  const changeLayoutDirection = (rtlMode) => {
    if (rtlMode) {
      const html = document.querySelector('html');
      html.setAttribute('dir', 'rtl');
    } else {
      const html = document.querySelector('html');
      html.setAttribute('dir', 'ltr');
    }
    dispatch(changeDirectionMode(rtlMode));
  };

  const darkmodeActivated = () => {
    document.body.classList.add('dark');
  };

  const darkmodeDiactivated = () => {
    document.body.classList.remove('dark');
  };
  let items = []
  if (userInfo && userInfo.role == "User") {
    items = [
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/dashboard`}>
          {t('Dashboard')}
        </NavLink>,
        'signings',
        !topMenu && (
          <NavLink className="menuItem-iocn" to={`${path}/dashboard`}>
            <UilCreateDashboard />
          </NavLink>
        ),
      ),
      getItem(t('shifts'), 'shifts', !topMenu && <UilEnvelope />, [
        getItem(
          <NavLink onClick={toggleCollapsed} to={`${path}/shifts/available`}>
            {t('Available shifts')}
          </NavLink>,
          'Available shifts',
          null,
        ),
        getItem(
          <NavLink onClick={toggleCollapsed} to={`${path}/shifts/not-hold`}>
            {t('Not held')}
          </NavLink>,
          'Not held',
          null,
        ),
        getItem(
          <NavLink onClick={toggleCollapsed} to={`${path}/shifts/ongoing`}>
            {t('Ongoing')}
          </NavLink>,
          'Ongoing',
          null,
        ),
        getItem(
          <NavLink onClick={toggleCollapsed} to={`${path}/shifts/held`}>
            {t('Held')}
          </NavLink>,
          'Held',
          null,
        ),
        getItem(
          <NavLink onClick={toggleCollapsed} to={`${path}/shifts/approved`}>
            {t('Approved')}
          </NavLink>,
          'Approved',
          null,
        ),
        getItem(
          <NavLink onClick={toggleCollapsed} to={`${path}/shifts/paid`}>
            {t('Paid')}
          </NavLink>,
          'Paid',
          null,
        ),
      ]),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/main/chat/private`}>
          {t('chat')}
        </NavLink>,
        'chat',
        !topMenu && (
          <NavLink className="menuItem-iocn" to={`${path}/main/chat/private`}>
            <UilChat />
          </NavLink>
        ),
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/salaryInfo`}>
          {t('salaryInfo')}
        </NavLink>,
        'salaryInfo',
        !topMenu && (
          <NavLink className="menuItem-iocn" to={`${path}/salaryInfo`}>
            <UilMoneyWithdrawal />
          </NavLink>
        ),
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/signings`}>
          {t('Signings')}
        </NavLink>,
        'Signings',
        !topMenu && (
          <NavLink className="menuItem-iocn" to={`${path}/signings`}>
            <UilEditAlt />
          </NavLink>
        ),
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/paychecks`}>
          {t('Paychecks')}
        </NavLink>,
        'Paychecks',
        !topMenu && (
          <NavLink className="menuItem-iocn" to={`${path}/paychecks`}>
            <UilMoneyWithdraw />
          </NavLink>
        ),
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/calendar/month`}>
          {t('Calendar')}
        </NavLink>,
        't-menu-calendar',
        !topMenu && (
          <NavLink className="menuItem-iocn" to={`${path}/calendar/month`}>
            <UilCalendarAlt />
          </NavLink>
        ),
      ),
    ];
  } else {
    items = [
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/dashboard`}>
          {t('Dashboard')}
        </NavLink>,
        'signings',
        !topMenu && (
          <NavLink className="menuItem-iocn" to={`${path}/dashboard`}>
            <UilCreateDashboard />
          </NavLink>
        ),
      ),
      getItem(t('User'), 'users', !topMenu && <UilChatBubbleUser />, [
        getItem(
          <NavLink onClick={toggleCollapsed} to={`${path}/users/list`}>
            {t('Userlist')}
          </NavLink>,
          'userlist',
          null,
        ),
        getItem(
          <NavLink onClick={toggleCollapsed} to={`${path}/pages/wizards/create/user`}>
            {t('CreateUser')}
          </NavLink>,
          'createuser',
          null,
        ),
      ]),
      getItem(t('shifts'), 'shifts', !topMenu && <UilEnvelope />, [
        getItem(
          <NavLink onClick={toggleCollapsed} to={`${path}/shifts/admin/list`}>
            {t('Available shifts')}
          </NavLink>,
          'Available shifts',
          null,
        ),
        getItem(
          <NavLink onClick={toggleCollapsed} to={`${path}/shifts/create`}>
            {t('Create Shift')}
          </NavLink>,
          'Create Shift',
          null,
        ),
      ]),
    ];
  }
  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
      // // eslint-disable-next-line no-nested-ternary
      defaultSelectedKeys={
        !topMenu
          ? [
            `${mainPathSplit.length === 1 ? 'home' : mainPathSplit.length === 2 ? mainPathSplit[1] : mainPathSplit[2]
            }`,
          ]
          : []
      }
      defaultOpenKeys={!topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : []}
      overflowedIndicator={<UilEllipsisV />}
      openKeys={openKeys}
      items={items}
    />
  );
}

MenuItems.propTypes = {
  toggleCollapsed: propTypes.func,
};

export default MenuItems;
