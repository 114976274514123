const actions = {
    SALARY_READ_BEGIN: 'SALARY_READ_BEGIN',
    SALARY_READ_SUCCESS: 'SALARY_READ_SUCCESS',
    SALARY_READ_ERR: 'SALARY_READ_ERR',

  
    salaryReadBegin: () => {
      return {
        type: actions.SALARY_READ_BEGIN,
      };
    },
  
    salaryReadSuccess: (data) => {
      return {
        type: actions.SALARY_READ_SUCCESS,
        data,
      };
    },
  
    salaryReadErr: (err) => {
      return {
        type: actions.SALARY_READ_ERR,
        err,
      };
    },
  
  };
  
  export default actions;
  