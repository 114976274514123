import actions from './actions';
import { DataService } from '../../config/dataService/dataService';

const {
    getUserInfoRequest,
    getUserInfoSuccess,
    getUserInfoFailure,
    getAllUserDataRequest,
    getAllUserDataSuccess,
    getAllUserDataFailure
} = actions;



const getUserInfo = () => {
    return async (dispatch) => {
        dispatch(getUserInfoRequest());
        try {
            const userData = await DataService.get('/user/')
            if (userData.data.code == 200) {
                dispatch(getUserInfoSuccess(userData.data.result));
            } else {
                dispatch(getUserInfoFailure('Registration failed!'));
            }
        } catch (error) {
            dispatch(getUserInfoFailure(error));
        }
    };
}
const getAllUsers = () => {
    return async (dispatch) => {
        dispatch(getAllUserDataRequest());
        try {
            const userData = await DataService.get('/user/all-users/')
            if (userData.data.code == 200) {
                dispatch(getAllUserDataSuccess(userData.data.result));
            } else {
                dispatch(getAllUserDataFailure('Registration failed!'));
            }
        } catch (error) {
            dispatch(getUserInfoFailure(error));
        }
    };
}

const updateUserInfo = (newUserData) => {
    return async (dispatch) => {
        dispatch(getUserInfoRequest());
        try {
            const userData = await DataService.put('/user/update', newUserData)
            if (userData.data.code == 200) {
                dispatch(getUserInfoSuccess(userData.data.result));
            } else {
                dispatch(getUserInfoFailure('Registration failed!'));
            }
        } catch (error) {
            dispatch(getUserInfoFailure(error));
        }
    };
}

const creatUser = (newUserData) => {
    return async (dispatch) => {
        try {
            const userData = await DataService.post('/user/create', newUserData)
            return userData.status;
        } catch (error) {
            return "Registration failed!"
        }
    };
}


export { getUserInfo, updateUserInfo, getAllUsers, creatUser };
